/* global ajax_object, google, Foundation */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line
// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  jarallaxElement();
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.5,
    disableParallax: function () {
      return Foundation.MediaQuery.is('large down');
    },
  });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    threshold: 0.8,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 200 * i);
          });
      }
      if ($(element).is('.landing-lifestyle')) {
        let $iframe = $(element).find('iframe');
        let videoSrc = $iframe.attr('src');
        $iframe.attr('src', videoSrc + '&autoplay=1');
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon, .custom-menu-icon').toggleClass('is-active');
      $('.header').toggleClass('is-active');
      $('.header-menu')
        .find('li.menu-item')
        .each(function (i) {
          let $this = $(this);
          if ($('.custom-menu-icon').hasClass('is-active')) {
            window.setTimeout(function () {
              $this.addClass('active');
            }, 200 * i);
          } else {
            $this.removeClass('active');
          }
        });
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon, .custom-menu-icon').removeClass('is-active');
      $('.header').removeClass('is-active');
      $('.header-menu')
        .find('li.menu-item')
        .each(function () {
          $(this).removeClass('active');
        });
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if (
        $('.menu-icon, .custom-menu-icon').hasClass('is-active') &&
        window.innerWidth < 641
      ) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

function ajax_load_page(pageId, href = null) {
  $.ajax({
    url: ajax_object.ajax_url,
    type: 'GET',
    data: {
      action: 'show_community',
      pageId,
    },
    beforeSend: function () {
      $('body').addClass('loading');
      $('.response').fadeOut();
      $('.js-slider').slick('unslick');
      if (href) {
        history.replaceState(null, 'Page', href);
      }
    },
    success: function (resp) {
      $('.response').html(resp.content).fadeIn();
      loadSlider();
      $('.acf-map').each(function () {
        render_map($(this));
      });
      ScrollOut({
        // offset: function () {
        //   return window.innerHeight - 200;
        // },
        once: true,
        onShown: function (element) {
          if ($(element).is('.ease-order')) {
            $(element)
              .find('.ease-order__item')
              .each(function (i) {
                let $this = $(this);
                $(this).attr('data-scroll', '');
                window.setTimeout(function () {
                  $this.attr('data-scroll', 'in');
                }, 200 * i);
              });
          }
        },
      });
    },
    complete: function () {
      $('body').removeClass('loading');
    },
  });
}

$('.js-show-page').on('click', function (e) {
  e.preventDefault();
  if (!$('body').hasClass('loading')) {
    let pageId = $(this).data('pageid');
    $('.communities-menu').find('.is-active').removeClass('is-active');
    $(this).addClass('is-active');
    let href = $(this).attr('href');
    ajax_load_page(pageId, href);
  }
});

$('.cummunities-menu-select').on('change', function () {
  if (!$('body').hasClass('loading')) {
    let pageId = this.value;
    let href = $(this).find(':selected').data('href');
    ajax_load_page(pageId, href);
  }
});

$('.slider-nav-select').on('change', function () {
  let filter = '.' + $(this).find(':selected').val();
  console.log(filter);
  floorPlanSlider.slick('slickUnfilter');
  floorPlanSlider.slick('slickFilter', filter);
  floorPlanSlider.slick('slickGoTo', 0);
});

$('.js-select').selectmenu({
  change: function () {
    $(this).trigger('change');
  },
});

function loadSlider() {
  $('.js-slider').slick({
    slidesToShow: 1,
    infinite: true,
    appendArrows: '.js-slider__arrows',
  });
}

let floorPlanSlider = $('.floor-plans-slider').slick({
  dots: false,
  infinite: true,
  appendArrows: '.floor-plans-slider__arrows',
  rows: 0,
});

$('.slider-nav__item').on('click', function (e) {
  e.preventDefault();
  let filter = '.' + $(this).data('filter');
  $(this).closest('.slider-nav').find('.active').removeClass('active');
  $(this).addClass('active');
  floorPlanSlider.slick('slickUnfilter');
  floorPlanSlider.slick('slickFilter', filter);
  floorPlanSlider.slick('slickGoTo', 0);
});

floorPlanSlider.on('afterChange', function (e, slick, currentSlide) {
  let slideType = $(slick.$slides.get(currentSlide)).data('type');
  $('.slider-nav__item').each(function () {
    $(this).removeClass('active');
    if ($(this).data('filter') == slideType) {
      $(this).addClass('active');
    }
  });
});

$('.reviews-slider').slick({
  slidesToShow: 1,
  infinite: true,
  draggable: true,
  appendArrows: '.reviews-slider__arrows',
  adaptiveHeight: true,
});

loadSlider();

$('body').on('click', 'a.back-to-top', function (e) {
  e.preventDefault();
  $('html, body').animate({ scrollTop: 0 }, 'slow');
});

function render_map($el) {
  // var
  var $markers = $el.find('.marker');
  var styles = [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e9e9e9',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dedede',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#333333',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f2f2f2',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
  ]; // Uncomment for map styling

  // vars
  var args = {
    zoom: 16,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    styles: styles, // Uncomment for map styling
  };

  // create map
  var map = new google.maps.Map($el[0], args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function () {
    add_marker($(this), map);
  });

  // center map
  center_map(map);
}

/*
 *  This function will add a marker to the selected Google Map
 */

// var infowindow;

function add_marker($marker, map) {
  class Popup extends google.maps.OverlayView {
    constructor(position, content) {
      super();
      this.position = position;
      content.classList.add('popup-bubble');
      // This zero-height div is positioned at the bottom of the bubble.
      const bubbleAnchor = document.createElement('div');
      bubbleAnchor.classList.add('popup-bubble-anchor');
      bubbleAnchor.appendChild(content);
      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement('div');
      this.containerDiv.classList.add('popup-container');
      this.containerDiv.appendChild(bubbleAnchor);
      // Optionally stop clicks, etc., from bubbling up to the map.
      Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    /** Called when the popup is added to the map. */
    onAdd() {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    }

    /** Called when the popup is removed from the map. */
    onRemove() {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      );
      // Hide the popup when it is far out of view.
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? 'block'
          : 'none';

      if (display === 'block') {
        this.containerDiv.style.left = divPosition.x + 'px';
        this.containerDiv.style.top = divPosition.y + 'px';
      }

      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    }
  }

  var latlng = new google.maps.LatLng(
    $marker.attr('data-lat'),
    $marker.attr('data-lng')
  );

  var href = $marker.data('href');

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    icon: $marker.data('marker-icon'), //uncomment if you use custom marker
    href,
  });

  // add to array
  map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($('.marker-info').length) {
    let popup = new Popup(latlng, document.querySelector('.marker-info'));
    google.maps.event.addListener(marker, 'mouseover', function () {
      popup.setMap(map);
    });
    google.maps.event.addListener(marker, 'mouseout', function () {
      popup.onRemove();
    });
  }

  if (marker.href) {
    google.maps.event.addListener(marker, 'click', function () {
      window.location.replace(marker.href);
    });
  }
}

/*
 *  This function will center the map, showing all markers attached to this map
 */

function center_map(map) {
  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each(map.markers, function (i, marker) {
    var latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );
    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length == 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
  } else {
    // fit to bounds
    map.fitBounds(bounds, 100);
  }
}

/*
 *  This function will render each map when the document is ready (page has loaded)
 */
$(document).ready(function () {
  $('.acf-map').each(function () {
    render_map($(this));
  });
});

$('.book-a-tour').on('click', function (e) {
  e.preventDefault();
  let offsetTop = $('.form-wrapper').offset().top;
  $('html').animate({ scrollTop: offsetTop - 50 }, 500, function () {
    $('.form-wrapper').addClass('bounce');
  });
});

$('.form-wrapper').on('animationend', function () {
  $(this).removeClass('bounce');
});

$('select.gfield_select').prepend(
  $('<option selected hidden>').text('Select one')
);
